<template>
    <div
        :id="modalName"
        class="modal"
        role="dialog"
        aria-hidden="true"
        data-backdrop="static"
        data-keyboard="false"
    >
    <div class="modal-dialog modal-lg modal-dialog-centered">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title">{{ $t('module.perujuk') }}</h5>
            </div>
            <form @submit.prevent="submitData()">
            <div class="modal-body">
                <input
                    type="hidden"
                    class="form-control"
                    v-model.number="registrasi.id"
                />
                <!-- <div class="form-group row">
                    <label class="col-lg-3 col-form-label">{{ $t('common.username') }}:</label>
                    <div class="col-lg-6">
                        <div :class='{"kt-spinner fix-spinner-index kt-spinner--sm kt-spinner--brand kt-spinner--right kt-spinner--input" : isLoginLoading }'>
                        <input
                            type="text"
                            class="form-control"
                            placeholder="Username"
                            v-model="registrasi.userName"
                            v-validate="'required|loginValid|max:50|uniqueLogin'"
                            data-vv-validate-on="blur"
                            data-vv-as="Username"
                            name="username"
                            :class="{'is-invalid': errors.has('username') }"/>
                        <div v-show="errors.first('username')" class="invalid-feedback">{{ errors.first('username') }}</div>
                        </div>
                    </div>
                </div> -->
                <!-- <div v-if="!registrasi.id && !passwordBcn">
                    <div class="form-group row">
                        <label class="col-lg-3 col-form-label">{{ $t('common.password') }}:</label>
                        <div class="col-lg-6">
                            <input
                                type="password"
                                class="form-control"
                                placeholder="Password"
                                v-model="registrasi.password"
                                v-validate="'required|min:8|max:50'"
                                data-vv-as="Password"
                                ref="password"
                                name="password"
                                :class="{'is-invalid': errors.has('password') }"/>
                            <div v-show="errors.first('password')" class="invalid-feedback">{{ errors.first('password') }}</div>
                        </div>
                    </div> -->
                    <!-- <div class="form-group row">
                        <label class="col-lg-3 col-form-label">{{ $t('common.rePassword') }}:</label>
                        <div class="col-lg-6">
                            <input
                                type="password"
                                class="form-control"
                                placeholder="Konfirmasi Password"
                                v-model="registrasi.konfirmasiPassword"
                                v-validate="'required|confirmed:password'"
                                data-vv-as="Konfirmasi Password"
                                name="pass_conf"
                                :class="{'is-invalid': errors.has('pass_conf') }"/>
                            <div v-show="errors.first('pass_conf')" class="invalid-feedback">{{ errors.first('pass_conf') }}</div>
                        </div>
                    </div>
                </div> -->
                <div class="form-group row">
                    <label class="col-lg-3 col-form-label">{{ $t('common.firstName') }}:</label>
                    <div class="col-lg-6">
                        <input
                            type="text"
                            class="form-control"
                            placeholder="Nama Depan"
                            v-model="registrasi.namaDepan"
                            v-validate="'required'"
                            data-vv-as="Nama Depan"
                            name="namaDepan"
                            :class="{'is-invalid': errors.has('namaDepan') }"/>
                        <div v-show="errors.first('namaDepan')" class="invalid-feedback">{{ errors.first('namaDepan') }}</div>
                    </div>
                </div>
                <div class="form-group row">
                    <label class="col-lg-3 col-form-label">{{ $t('common.lastName') }}:</label>
                    <div class="col-lg-6">
                        <input
                            type="text"
                            class="form-control"
                            placeholder="Nama Belakang"
                            v-model="registrasi.namaBelakang"
                            v-validate="'required'"
                            data-vv-as="Nama Belakang"
                            name="namaBelakang"
                            :class="{'is-invalid': errors.has('namaBelakang') }"/>
                        <div v-show="errors.first('namaBelakang')" class="invalid-feedback">{{ errors.first('namaBelakang') }}</div>
                    </div>
                </div>
                <div class="form-group row">
                    <label class="col-lg-3 col-form-label">{{ $t('common.kategori') }}:</label>
                    <div class="col-lg-6">
                        <kategori-select
                            v-model="registrasi.kategoriId"
                            :validation="'required'"
                            :value-label="'id'"
                        ></kategori-select>
                    </div>
                </div>
                <div class="form-group row">
                    <label class="col-lg-3 col-form-label">{{ $t('common.email') }}:</label>
                    <div class="col-lg-6">
                        <div :class='{"kt-spinner fix-spinner-index kt-spinner--sm kt-spinner--brand kt-spinner--right kt-spinner--input" : isEmailLoading }'>
                        <input
                            type="email"
                            class="form-control"
                            placeholder="Email"
                            v-model="registrasi.email"
                            v-validate="'required|email'"
                            data-vv-validate-on="blur"
                            data-vv-as="Email"
                            name="email"
                            :class="{'is-invalid': errors.has('email') }"/>
                        <div v-show="errors.first('email')" class="invalid-feedback">{{ errors.first('email') }}</div>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <label class="col-lg-3 col-form-label">{{ $t('common.phoneNo') }}:</label>
                    <div class="col-lg-8">
                        <telepon-repeater v-for="(item, i) in registrasi.telepon"
                            :key="'telp'+i"
                            :data="item"
                            :index="i"
                            @deltelp="deleteTelp" >
                        </telepon-repeater>
                        <div class="row mb-4">
                            <div class="col-12">
                                <button type="button" class="btn btn-info btn-sm" @click="pushTelepon">
                                    <i class="la la-plus"></i> {{ $t('button.addPhone') }}
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <label class="col-lg-3 col-form-label">{{ $t('common.alamat') }}:</label>
                    <div class="col-lg-8">
                        <alamat-repeater v-for="(item, j) in registrasi.alamat"
                            :key="'alm'+j"
                            :data="item"
                            :index="j"
                            @delalamat="deleteAlmt"
                        >
                        </alamat-repeater>
                        <div class="row mb-4">
                            <div class="col-12">
                                <button type="button" class="btn btn-info btn-sm" @click="pushAlamat">
                                    <i class="la la-plus"></i> {{ $t('button.addAddress') }}
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-clean btn-bold btn-upper btn-font-md" @click="clearData()" >{{ $t('button.close') }}</button>
                <button type="submit" class="btn btn-default btn-bold btn-upper btn-font-md">{{ $t('button.save') }}</button>
            </div>
            </form>
        </div>
    </div>
  </div>
</template>

<script>
import SweetAlertTemplate from "./../../_general/SweetAlert";
import BlockUI from "../../_js/BlockUI";
const blockUI = new BlockUI();
const SweetAlert = Vue.extend(SweetAlertTemplate);
import Registrasi from "../../../model/registrasi-perujuk-model";
import { RepositoryFactory } from "../../../repositories/RepositoryFactory";
const RegistrasiPerujukRepository = RepositoryFactory.get("registrasiPerujuk");
const UserRepository = RepositoryFactory.get("user");
import KategoriSelect from "./../../_select/Kategori.vue";
import TipeTelepon from "./../../../constants/tipe-telepon-enum";
import TeleponRepeater from "./../_repeaters/TeleponRepeater";
import AlamatRepeater from "./../_repeaters/AlamatRepeater";
import { Validator } from 'vee-validate';
export default {
  components: {
    KategoriSelect,
    TeleponRepeater,
    AlamatRepeater
  },
  data() {
    return {
      registrasi: new Registrasi(),
      isLoginLoading: false,
      isEmailLoading: false,
      oldLogin: null,
      oldEmail: null,
    };
  },
  props: {
    modalName: { type: String },
    modalData: { type: Object },
    passwordBcn: {type: Boolean, default: false}
  },
  provide() {
    return {
      $validator: this.$validator
    };
  },
  methods: {
    deleteAlmt: function(index) {
      this.registrasi.alamat.splice(index, 1);
    },
    deleteTelp: function(index) {
      this.registrasi.telepon.splice(index, 1);
    },
    pushAlamat: function() {
      this.registrasi.alamat.push({
        nama: null,
        tipe: null,
        jalan: null
      });
    },
    pushTelepon: function() {
      this.registrasi.telepon.push({
        nomor: null,
        tipe: null
      });
    },
    clearData: function() {
      $("#" + this.modalName).modal("hide");
      this.$emit("update:showModal", false);
    },
    constructData: function() {
      this.registrasi.telepon.push({
        nomor: null,
        tipe: TipeTelepon.HP_PRIMARY
      });
      this.registrasi.alamat.push({
        nama: null,
        tipe: null,
        jalan: null
      });
    },
    updateData: function(data) {
      this.registrasi = Object.assign({}, data);
      this.oldLogin = this.registrasi.userName;
      this.oldEmail = this.registrasi.email;
      this.registrasi.password = "dummyPassword";
      this.registrasi.konfirmasiPassword = "dummyPassword";
      this.registrasi.persetujuanEula = true;

      const index = this.registrasi.telepon.findIndex(x => x.tipe == TipeTelepon.HP_PRIMARY);
      if(index > -1){
          const temp = this.registrasi.telepon[index];
          this.deleteTelp(index);
          this.registrasi.telepon.unshift(temp);
      }
    },
    submitData: function() {
      var vx = this;

      this.$validator.validateAll().then(result => {
        if (result) {
          if (vx.registrasi.id) {
            let instance = new SweetAlert().showConfirm({
              swalTitle: "Apakah Anda yakin?",
              swalText: "Perubahan data Perujuk akan tersimpan.",
              swalType: "info",
              onConfirmButton: function() {
                blockUI.blockModal();
                vx.update(vx.registrasi);
              },
              onCancelButton: function() {
                blockUI.unblockModal();
              }
            });
          } else {
            let instance = new SweetAlert().showConfirm({
              swalTitle: "Apakah Anda yakin?",
              swalText: "Data Perujuk akan ditambahkan.",
              swalType: "info",
              onConfirmButton: function() {
                blockUI.blockModal();
                new Promise((resolve,reject) => {
                    vx.registrasi.password = (vx.passwordBcn)? vx.registrasi.email : vx.registrasi.password;
                    vx.registrasi.konfirmasiPassword = (vx.passwordBcn)? vx.registrasi.email : vx.registrasi.konfirmasiPassword;
                    resolve(true);
                }).then(res =>{
                    vx.create(vx.registrasi);
                })
              },
              onCancelButton: function() {
                blockUI.unblockModal();
              }
            });
         }
          return;
        }
      });
    },
    async update(payload) {
      var vx = this;

      await RegistrasiPerujukRepository.update(payload)
        .then(response => {
          new Promise((resolve, reject) => {
            vx.$emit("event", resolve);
          }).then(response => {
            let instance = new SweetAlert().showInfo({
              swalTitle: "Berhasil!",
              swalText: "Perubahan data Perujuk berhasil disimpan.",
              swalType: "success",
              onConfirmButton: function() {
                blockUI.unblockModal();
                vx.clearData();
              }
            });
          });
        })
        .catch(error => {
          let instance = new SweetAlert().showInfo({
            swalTitle: "Gagal!",
            swalText: "Perubahan data Perujuk gagal disimpan.",
            swalType: "error",
            onConfirmButton: function() {
              blockUI.unblockModal();
            }
          });
        });
    },
    checkLogin: function(value, resolve){
        var params = 'login%3A'+value;
        UserRepository.search(params).then((response) => {
            this.isLoginLoading = false;
            if (response.data.length > 0) {
                return resolve({
                    valid: false,
                    data: {
                    message: `Login ${value} sudah terpakai`
                    }
                });
            } else {
                return resolve({
                    valid: true
                });
            }

        }).catch(err => {
            this.isLoginLoading = false;
            var msg = (error.message) ? error.message : error;
            this.showToastError('check login : ' + msg);
            return resolve({
                valid: false,
                data : {
                    message: `Gagal Validasi Check Login`
                }
            })
        })
    },
    checkEmail: function(value, resolve){
        const split = value.split('@');
        var params = 'email%3A'+split[0];
        UserRepository.search(params).then((response) => {
            this.isEmailLoading = false;
            const temp = response.data.filter(x => x.email.toLowerCase() == value.toLowerCase());
            if (temp.length > 0) {
                return resolve({
                    valid: false,
                    data: {
                    message: `Email ${value} sudah terpakai`
                    }
                });
            } else {
                return resolve({
                    valid: true
                });
            }

        }).catch(err => {
            this.isEmailLoading = false;
            var msg = (error.message) ? error.message : error;
            this.showToastError('check email : ' + msg);
            return resolve({
                valid: false,
                data : {
                    message: `Gagal Validasi Check Email`
                }
            })
        })
    },
    async create(payload) {
      var vx = this;
      await RegistrasiPerujukRepository.register(payload)
        .then(response => {
          new Promise((resolve, reject) => {
            vx.$emit("event", resolve);
          }).then(response => {
            let instance = new SweetAlert().showInfo({
              swalTitle: "Berhasil!",
              swalText: "Data registrasi berhasil ditambahkan.",
              swalType: "success",
              onConfirmButton: function() {
                blockUI.unblockModal();
                vx.clearData();
              }
            });
          });
        })
        .catch(error => {
          let instance = new SweetAlert().showInfo({
            swalTitle: "Gagal!",
            swalText: "Data registrasi gagal ditambahkan.",
            swalType: "error",
            onConfirmButton: function() {
              blockUI.unblockModal();
            }
          });
        });
    }
  },
  mounted() {
    $("#" + this.modalName).modal();
    if (this.modalData) {
      this.updateData(this.modalData);
    } else {
      this.constructData();
    }

    const isUniqueLogin = value =>
        new Promise(resolve => {
            this.errors.remove('login');
            this.isLoginLoading = true;
            if(this.registrasi.id){
                if(value == this.oldLogin) {
                    this.isLoginLoading = false;
                    return resolve({
                        valid: true
                    });
                } else {
                    this.checkLogin(value, resolve);
                }
            } else {
                this.checkLogin(value, resolve);
            }
        });

    // const isEmailUnique = value =>
    //     new Promise(resolve => {
    //         this.errors.remove('email');
    //         this.isEmailLoading = true;
    //         if(this.registrasi.id){
    //             if(value == this.oldEmail) {
    //                 this.isEmailLoading = false;
    //                 return resolve({
    //                     valid: true
    //                 });
    //             } else {
    //             this.checkEmail(value, resolve);
    //             }
    //         } else {
    //             this.checkEmail(value, resolve);
    //         }

    //     })
    Validator.extend("uniqueLogin", {
      validate: isUniqueLogin,
      getMessage: (field, params, data) => data.message
    });
    // Validator.extend("uniqueEmail", {
    //   validate: isEmailUnique,
    //   getMessage: (field, params, data) => data.message
    // });
    Validator.extend('loginValid', {
        getMessage: field => 'Login hanya dapat berisi huruf, angka dan karakter spesial seperti: . _ -  ',
        validate: value => {
           const regex = value.match(/^[_.A-Za-z0-9-]*$/g);
           return ( regex == null ) ? false : true;
        }
    });
  }
};
</script>

<style>
</style>
