export default function RegistrasiPerujuk() {
    return {
        id: null,
        perujukId: null,
        namaDepan: null,
        namaBelakang: null,
        persetujuanEula: true,
        alamat: [],
        telepon: [],
        userName: null,
        email: null,
        kategoriId: null,
        password: null,
        konfirmasiPassword: null
    };
}
